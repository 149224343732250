import { createApp } from 'vue';
import { createVuestic } from 'vuestic-ui';
import axios from 'axios';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'vuestic-ui/css';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(createVuestic());

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_SERVER_URL}`,
});

axios.defaults.baseURL = `${process.env.VUE_APP_SERVER_URL}`;
app.config.globalProperties.$axios = axiosInstance;

app.mount('#app');
