<script setup lang="ts"></script>

<template>
  <section>
    <div class="content">
      <h1>Leider war dies nicht erfolgreich</h1>
    </div>
    <div class="content">
      <router-link to="/">
        <va-button class="mt-5 va-button" variant="primary">Zurück</va-button>
      </router-link>
    </div>
  </section>
</template>

<style scoped lang="scss">
section {
  display: grid;
  width: 90vw;
  height: 90vh;
  justify-content: center;
  align-self: center;

  .content {
    display: flex;
    justify-content: center;
    align-self: center;
  }
}
</style>
