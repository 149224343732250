export enum States {
  RUNNING = 'running',
  PAUSED = 'paused',
  STOPPED = 'stopped',
}

export enum runner {
  RUNNING = 'Pausieren',
  PAUSED = 'Anschalten',
}

export enum colorCode {
  ORANGE = '#DD7E3A',
  GREEN = '#10FD0C',
  RED = '#FF3737',
  PURPLE = '',
}
