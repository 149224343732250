import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a9b92048"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "seatContainer",
  class: "seat-container"
}
const _hoisted_2 = { class: "seat-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "seat-information" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: `/img/seats/${$props.seatval?.seatNumber}.png`,
        alt: "seat"
      }, null, 8, _hoisted_3),
      _createElementVNode("h1", null, _toDisplayString($props.seatval.seat), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", null, _toDisplayString($props.seatval.arena), 1),
        _createElementVNode("h2", null, _toDisplayString($props.seatval.location), 1)
      ])
    ])
  ], 512))
}