<template>
  <div ref="seatContainer" class="seat-container">
    <div class="seat-wrapper">
      <img :src="`/img/seats/${seatval?.seatNumber}.png`" alt="seat" />
      <h1>{{ seatval.seat }}</h1>
      <div class="seat-information">
        <h3>{{ seatval.arena }}</h3>
        <h2>{{ seatval.location }}</h2>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: ['seatval'],
};
</script>

<style scoped>
.seat-container {
  max-width: 100%;

  .seat-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
    }

    h1 {
      position: absolute;
      top: 5%;
      left: 48%;
      transform: translateX(-50%);
      color: black;
      font-size: clamp(0.75rem, 2vw, 2rem);
      font-weight: bolder;
      pointer-events: none;
    }

    .seat-information {
      z-index: 3;
      padding: 5%;
      width: auto;
      position: absolute;
      top: 25%;
      left: 48%;
      transform: translateX(-50%);
      color: black;
      font-size: clamp(0.5rem, 2vw, 1.5rem);
      font-weight: bolder;
      pointer-events: none;
      backdrop-filter: blur(420px);
      border-radius: 40px;
    }
  }
}
</style>
