import { createStore } from 'vuex';

export default createStore({
  state: {
    seatNumber: null,
    seat: null,
    arena: '',
    location: '',
    stadion: '',
  },
  getters: {},
  mutations: {
    SET_DATA(state, payload) {
      state.seatNumber = payload.seatNumber;
      state.seat = payload.seat;
      state.arena = payload.arena;
      state.location = payload.location;
      state.stadion = payload.stadion;
    },
  },
  actions: {
    setData({ commit }, payload) {
      commit('SET_DATA', payload);
    },
  },
  modules: {},
});
