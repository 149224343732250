<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { colorCode, runner, States } from '@/interfaces/States';
import { loadStripe } from '@stripe/stripe-js';
import { useRoute } from 'vue-router';
import axios, { AxiosInstance } from 'axios';
import { useToast } from 'vuestic-ui';
import SeatComponent from '../components/SeatComponent.vue';
import { useStore } from 'vuex';
import { io } from 'socket.io-client';

const { notify } = useToast();

interface buttonItems {
  pricing: string;
  name: string;
  metadata: {
    price: number;
    time: number;
  };
}

//* helpers for countdown
let countDown = 0;
let intervalId: any = 0;
//* variable to countdown time that chair is still active
const time = ref<number>(0);
//* variable if timer is running or not
const state = ref<States>(States.STOPPED);
const showModal = ref<boolean>(false);
//* helper for countdown
const isCountDown = ref<boolean>(false);
// ? variable for resume / pause button
const resumeText = ref<runner>(runner.RUNNING);
//* lightbox in timer
const indicationLightStyle = computed(() => {
  if (state.value === States.RUNNING) {
    return `background-color: ${colorCode.GREEN};`;
  }
  return state.value === States.PAUSED
    ? `background-color: ${colorCode.ORANGE};`
    : `background-color: ${colorCode.RED};`;
});

//* format time when timer is expired
const endDate = computed(() => {
  const currentTime = new Date();
  currentTime.setSeconds(currentTime.getSeconds() + time.value);
  return currentTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
});

function notifyUser(
  title: string,
  message: string,
  color: string,
  closeable = false
) {
  notify({
    title,
    message,
    color,
    closeable,
  });
}

//* show timer running in minutes and seconds
const formattedTime = computed(() => {
  const minutes = Math.floor(time.value / 60);
  const seconds = time.value % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
});

//* set timer time
async function countDownTimer() {
  if (countDown && !isCountDown.value) {
    isCountDown.value = true;
    if (countDown > 0) {
      intervalId = setInterval(async () => {
        time.value--;
        if (time.value === 0) {
          clearInterval(intervalId);
          isCountDown.value = false;
          state.value = States.STOPPED;
          //* stop chair function
          //! remove dali and change it back to dali!
          await fetch('/api/dali/off', {
            method: 'POST',
            body: JSON.stringify({
              id: 1,
            }),
          });
        }
      }, 1000);
    }
  }
}

async function changeTime(newTime: number) {
  //* start chair
  try {
    //! remove dali and change it back to dali!
    await fetch('/api/dali/on', {
      method: 'POST',
      body: JSON.stringify({
        id: 1,
      }),
    });
  } catch (e) {
    console.log(e);
  }
  time.value += newTime;
  state.value = States.RUNNING;
  countDown = time.value;
  if (resumeText.value === runner.PAUSED) resumeText.value = runner.RUNNING;
  if (!isCountDown.value) await countDownTimer();

  if (showModal.value) showModal.value = false;
}

//* stripe payment
async function checkout(timerTime: number, price: string, productName: string) {
  //* api call to get session id
  try {
    localStorage.setItem('timerTime', timerTime?.toString());
    if (!timerTime || !price)
      throw new Error(
        'Zeit oder Preis wurde nicht für diese Option hinterlegt!'
      );
    const response = await fetch('/api/payments/create-checkout-session', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        time: timerTime, //! add * 60 for minutes
        completeTime: time.value,
        seatValues: seatValues.value,
        pricing: price,
        productName,
      }),
    });

    const { sessionId } = await response.json();

    //* redirect to, checkout site
    const stripe = await loadStripe(
      <string>process.env.VUE_APP_STRIPE_PUBLICKEY?.toString()
    );
    await stripe?.redirectToCheckout({ sessionId });
  } catch (e) {
    notifyUser('Error', `${e}`, 'danger');
  }
}

//* stripe payment validation and timer handling
function calculateRemainingSeconds(
  startTimeString: string,
  endTimeString: string
): number {
  const startTime = new Date(startTimeString);
  const endTime = new Date(endTimeString);
  const now = new Date();

  if (now < startTime) {
    return -1;
  }
  if (now > endTime) {
    return 0;
  }
  const timeDifferenceMs = endTime.getTime() - now.getTime();
  return Math.ceil(timeDifferenceMs / 1000);
}

async function getPaymentData(paymentId: string) {
  try {
    if (paymentId) {
      try {
        const response = await fetch(
          `/api/payments/payment-completed/${paymentId}`
        );
        const data = await response.json();
        const remainingSeconds = calculateRemainingSeconds(
          data.startTime,
          data.endTime
        );

        seatValues.value = data.seatValues;

        if (data.isValidated && remainingSeconds && paymentId) {
          changeTime(remainingSeconds);
        } else {
          notifyUser(
            'Ein Fehler ist aufgetreten!',
            'Leider ist bei der Zahlung etwas Schiefgelaufen!',
            'danger'
          );
        }
      } catch (e) {
        notifyUser('Ein Fehler ist aufgetreten!', `${e}`, 'info');
      }
    }
  } catch (error) {
    notifyUser(
      'Ein Fehler ist aufgetreten!',
      `Fehlercode für die Administratoren: ${error}`,
      'danger'
    );
  }
}
const router = useRoute();
const store = useStore();
const seatValues = ref({
  seat: null,
  seatNumber: null,
  arena: null,
  location: null,
  stadion: null,
});

const socket = ref();
const buttonArray = ref<Array<buttonItems>>([]);

onBeforeMount(async () => {
  const key = process.env.VUE_APP_STRIPE_PUBLICKEY;
  console.log('Das ist der Public Key');
  console.log(key);

  try {
    const response = await axios.get('/api/payments/products');
    buttonArray.value = response.data;
    console.log(response);
  } catch (e) {
    notifyUser('Error beim Bereitstellen der Optionen', `${e}`, 'danger');
  }
});

onMounted(async () => {
  socket.value = io(<string>process.env.VUE_APP_SERVER_URL);

  socket.value.on('products', (products: buttonItems[]) => {
    buttonArray.value = products;
  });

  const paymentId = router?.params?.id;
  await getPaymentData(paymentId?.toLocaleString());

  if (store.state.seat)
    seatValues.value = {
      seat: store.state.seat,
      seatNumber: store.state.seatNumber,
      arena: store.state.arena,
      location: store.state.location,
      stadion: store.state.stadion,
    };

  document.getElementById('head-component')!.style.backgroundImage =
    `url(/img/arenas/${seatValues.value.stadion}.png)`;
});

async function pauseTimer(this: { $axios: AxiosInstance }) {
  try {
    const response = await this.$axios.post('/payment/pauseTimer', {
      id: router?.params?.id,
      remainingTime: time.value,
      isPaused: state.value !== States.PAUSED, //* true, wenn pausiert wird, false beim Fortsetzen
    });

    if (response.data.success) {
      if (state.value === States.PAUSED) {
        //* Timer fortsetzen
        state.value = States.RUNNING;
        await countDownTimer();
        resumeText.value = runner.RUNNING;
      } else {
        //* Timer pausieren
        clearInterval(intervalId);
        isCountDown.value = false;
        resumeText.value = runner.PAUSED;
        state.value = States.PAUSED;
      }
    } else {
      console.error('Fehler beim Aktualisieren des Timer-Status im Backend');
    }
  } catch (error) {
    console.error('Fehler bei der HTTP-Anfrage:', error);
  }
}
</script>

<template>
  <!--todo: add seat and background of stadion in head + animation if started -->
  <!-- todo: https://codepen.io/lukesmetham/pen/yJVwVr -->
  <!-- todo: https://codepen.io/innersociety/pen/rEXoer -->
  <section class="home-view">
    <div id="head-component" class="heading">
      <div class="seat-component">
        <SeatComponent :seatval="seatValues" />
      </div>
      <h1 id="header1">Zeitinformation</h1>
      <h1 id="header2">Ihr Platz</h1>
      <div class="time-container">
        <span id="indication-light" :style="indicationLightStyle"></span>
        <div class="texts">
          <h1 class="mt-5">{{ formattedTime }}</h1>
          <h5 v-show="state === States.RUNNING" class="mt-3">
            Aktiv bis <span style="color: #27ec7c">{{ endDate }}</span> Uhr
          </h5>
        </div>
      </div>
    </div>

    <div class="body-content">
      <div class="buttons">
        <div v-if="state === States.STOPPED" class="inactive-view">
          <button
            v-for="item in buttonArray"
            :key="item.name"
            class="opt"
            @click="checkout(item.metadata.time, item.pricing, item.name)"
          >
            {{ item?.name }}
          </button>
        </div>
        <div v-else class="active-view">
          <button class="opt more-time" @click="showModal = true">
            <span>Verlängern</span>
          </button>
          <button class="opt more-time" @click="pauseTimer">
            {{ resumeText }}
          </button>
        </div>
      </div>
    </div>
    <VaModal
      id="continueModal"
      v-model="showModal"
      ok-text="Schließen"
      :mobile-fullscreen="false"
      cancel-text=""
    >
      <div class="button-group">
        <div class="in-modal-view">
          <button
            v-for="item in buttonArray"
            :key="item.name"
            class="opt"
            @click="checkout(item.metadata.time, item.pricing, item.name)"
          >
            {{ item?.name }}
          </button>
        </div>
      </div>
    </VaModal>
  </section>
</template>

<style lang="scss" scoped>
.home-view {
  background-color: #f6f6f6;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 0.45fr 0.65fr;

  .seat-component {
    grid-row: 2;
    width: clamp(25vw, 5vw, 20vw);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50%;
  }

  #header1 {
    font-size: clamp(1rem, 2vw, 2rem);
    grid-column: 2;
    padding: 2%;
    backdrop-filter: blur(10px);
    border-radius: 40px;
    margin-top: 2%;
  }

  #header2 {
    font-size: clamp(1rem, 2vw, 2rem);
    grid-row: 1;
    padding: 2%;
    backdrop-filter: blur(10px);
    border-radius: 40px;
    margin-top: 2%;
    margin-left: 50%;
  }

  .heading {
    font-weight: bolder;
    color: white;
    display: grid;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    grid-template-rows: 0fr 1fr;
    grid-template-columns: 0.5fr 1fr;
    width: 100%;
    height: 100%;
    background-size: 100vw;
    background-position-y: -15em;
    border-radius: 0 0 90px 90px;

    h1 {
      padding-top: 1%;
      font-size: 2.5em;
    }

    .time-container {
      grid-row: 2;
      grid-column: 2;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: clamp(18vw, 4vw, 15vw);
      height: 65%;
      backdrop-filter: blur(80px);
      border-radius: 20px;

      #indication-light {
        width: 10%;
        height: 10%;
        left: 10%;
        top: 10%;
        background-color: #1a8870;
        border-radius: 100%;

        position: absolute;
      }

      .texts {
        display: grid;
        justify-items: center;
        align-items: center;

        h1 {
          font-size: clamp(1rem, 4vw, 4rem);
        }

        h5 {
          font-size: clamp(0.5rem, 1.5vw, 2rem);
        }
      }
    }
  }

  .body-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .buttons {
      margin-top: 1vh;
      overflow: scroll;
      width: 75%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .inactive-view {
        backdrop-filter: blur(420px);
        width: 90%;
        height: 75%;
        display: grid;
        align-items: center;
        justify-items: center;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        gap: 5%;
      }

      .active-view {
        width: 90%;
        height: 100%;
        display: grid;
        align-items: center;
        justify-items: center;
        grid-template-rows: 1fr 1fr;
        gap: 5%;

        .opt {
          display: inline-block;
          width: 100% !important;
        }
        :first-child {
          rotate: 180deg;

          span {
            display: block;
          }
        }
      }
    }
  }
}

.opt:hover {
  background-color: #cc6112;
  transition-duration: 0.1s;
}

.opt {
  scroll-behavior: auto;
  position: relative;
  color: white;
  font-weight: bolder;
  background-color: #dd7e3a;
  border: 0;
  border-radius: 20px 20px 75px 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: clamp(9vh, 5vh, 6vh);
  cursor: pointer;
  box-shadow: 0 10px 15px -5px #988989;
  transition:
    background-color 0.1s,
    box-shadow ease-in-out;
}

.opt:hover {
  background-color: #cc6112;
  transition-delay: 0.1s;
}

.opt:active {
  box-shadow: 0 5px 10px -3px #988989;
}

.opt:active:after {
  box-shadow: 20px 20px 75px 75px #988989;
  position: absolute;
  border-radius: 4em;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0s;
}

.button-group {
  width: 100%;
  height: 25vh;

  .in-modal-view {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 2%;
  }
}

/* ===== Scrollbar CSS ===== */
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f6f6f6;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 10px;
}

*::-webkit-scrollbar-button {
  display: none;
}
</style>
